<template>
  <div class="arrear">
    <van-nav-bar
      title="欠费追缴"
      left-arrow
      @click-left="onClickLeft"/>
      <p class="arrear_title"> 京A·12345 <span>总欠费 ¥19.5</span> </p>
      <ul class="arrear-content">
        <li v-for="(item, index) in list" :key="index" @click="toDetails">
          <h5>{{item.name}}</h5>
          <p>{{item.time}} <span>{{item.minute}}</span></p>
          <span>¥{{item.money}}</span>
        </li>
      </ul>
      <van-button class="arrear_btn" type="warning">缴纳欠费</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
      list: [
        {
          name: '公交大厦',
          time: '2016-11-08',
          minute: '14:57',
          money: '7'
        },
        {
          name: '西大街',
          time: '2016-10-28',
          minute: '12:32',
          money: '6.5'
        }
      ]
    }
  },
  methods: {
    onClickLeft() {
      console.log('返回');
    },
    toDetails() {
      this.$router.push({path: '/details'})
    }
  }
}
</script>
<style lang="less" scoped>
.arrear{
  min-height: 100vh;
  background-color: rgb(230, 232, 235);
  padding-bottom: 100px;
  box-sizing: border-box;
  position: relative;
}
.arrear_title{
  margin: 0;
  background-color: rgb(47,164,232);
  color: #fff;
  font-size: 40px;
  padding: 50px 30px;
  >span{
    float: right;
  }
}
.arrear-content{
  padding: 20px;
  >li{
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    background-color: #fff;
    >h5{
      font-size: 30px;
      margin: 0;
    }
    >p{
      font-size: 24px;
      margin: 0;
      margin-top: 20px;
    }
    >span{
      position: absolute;
      right: 30px;
      top: 40px;
      font-size: 45px;
      color: red;
    }
  }
}
.arrear_btn{
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>