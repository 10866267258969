import {
  List, Toast, Search, Tabbar, TabbarItem, Button, Tab, Tabs, Col, Row, DropdownMenu, DropdownItem, TreeSelect, Image as VanImage, Lazyload, Empty, Cell, CellGroup, Popup, Picker, Divider, Pagination, Field, Collapse, CollapseItem,
  Form, DatetimePicker, Loading, Overlay, Dialog, NavBar, Icon, Checkbox, CheckboxGroup, PullRefresh, RadioGroup, Radio
} from 'vant'

const components = {
  List, Toast, Search, Tabbar, TabbarItem, Button, Tab, Tabs, Col, Row, DropdownMenu, DropdownItem, TreeSelect, VanImage, Lazyload, Empty, Cell, CellGroup, Popup, Picker, Divider, Pagination, Field, Collapse, CollapseItem,
  Form, DatetimePicker, Loading, Overlay, Dialog, NavBar, Icon, Checkbox, CheckboxGroup, PullRefresh, RadioGroup, Radio
}

const componentsHandler = {
  install(Vue) {
    Object.keys(components).forEach(key => Vue.use(components[key]))
  }
}

export default componentsHandler