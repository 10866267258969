<template>
  <div class="addCarWrapper">
    <van-nav-bar v-if="false" title="欠费查询" class="title-nav-bar" />
    <div class="lcontainer">
      <div class="carTip telMar">请选择您的车牌颜色</div>
      <!-- 车牌颜色 -->
      <div class="carColorType">
        <div
          class="carColor"
          :class="[colorIndex == index + 1 ? item.style : '']"
          @click="toggleClass(index)"
          v-for="(item, index) in colors"
          :key="index"
        >
          {{ item.color }}
        </div>
      </div>
      <div class="chepai" v-if="num == 1 || num == 2 || num == 3">
        <div class="cen">
          <div
            class="tel province first"
            :class="len == 0 ? 'curr' : ''"
            @click="d1(0)"
            data-index="0"
          >
            <span style="display: block">{{ carNo[0] }}</span>
            <img class="dropdown" src="@/assets/images/dropdown.png" />
          </div>
          <div
            class="tel"
            :class="len == 1 ? 'curr' : ''"
            @click="d2(1)"
            data-index="1"
          >
            {{ carNo[1] }}
          </div>
          <div
            class="tel"
            :class="len == 2 ? 'curr' : ''"
            @click="d2(2)"
            data-index="2"
          >
            {{ carNo[2] }}
          </div>
          <div
            class="tel"
            :class="len == 3 ? 'curr' : ''"
            @click="d2(3)"
            data-index="3"
          >
            {{ carNo[3] }}
          </div>
          <div
            class="tel"
            :class="len == 4 ? 'curr' : ''"
            @click="d2(4)"
            data-index="4"
          >
            {{ carNo[4] }}
          </div>
          <div
            class="tel"
            :class="len == 5 ? 'curr' : ''"
            @click="d2(5)"
            data-index="5"
          >
            {{ carNo[5] }}
          </div>
          <div
            class="tel"
            :class="len == 6 ? 'curr' : ''"
            @click="d2(6)"
            data-index="6"
          >
            {{ carNo[6] }}
          </div>
        </div>
      </div>
      <!-- 新能源 -->
      <div class="chepai" v-if="num == 4">
        <div class="cen">
          <div
            class="tel province first"
            :class="len == 0 ? 'curr' : ''"
            @click="d1(0)"
            data-index="0"
          >
            <span>{{ carNo[0] }}</span>
            <img class="dropdown" src="@/assets/images/dropdown.png" />
          </div>
          <div
            class="tel"
            :class="len == 1 ? 'curr' : ''"
            @click="d2(1)"
            data-index="1"
          >
            {{ carNo[1] }}
          </div>
          <div
            class="tel"
            :class="len == 2 ? 'curr' : ''"
            @click="d2(2)"
            data-index="2"
          >
            {{ carNo[2] }}
          </div>
          <div
            class="tel"
            :class="len == 3 ? 'curr' : ''"
            @click="d2(3)"
            data-index="3"
          >
            {{ carNo[3] }}
          </div>
          <div
            class="tel"
            :class="len == 4 ? 'curr' : ''"
            @click="d2(3)"
            data-index="4"
          >
            {{ carNo[4] }}
          </div>
          <div
            class="tel"
            :class="len == 5 ? 'curr' : ''"
            @click="d2(5)"
            data-index="5"
          >
            {{ carNo[5] }}
          </div>
          <div
            class="tel"
            :class="len == 6 ? 'curr' : ''"
            @click="d2(6)"
            data-index="6"
          >
            {{ carNo[6] }}
          </div>
          <div
            class="tel"
            :class="len == 7 ? 'curr' : ''"
            @click="d2(7)"
            data-index="7"
          >
            {{ carNo[7] }}
          </div>
        </div>
      </div>
      <!-- <div>{{cityName}}</div> -->
      <!-- 省 -->
      <div class="provinces" v-show="!hidden1">
        <div
          class="pro_li lf"
          v-for="(item, index) in item1"
          :class="{ keyboardSwitch: index == 30 }"
          :key="index"
          @click="sheng(item, index)"
        >
          {{ item }}
        </div>
        <div class="pro_li lf" @click="hide">⇩</div>
      </div>
      <div v-show="!flag" @click="routerIndex" class="ignore">跳过</div>
      <!-- 号码	 -->
      <div class="keyNums" v-show="!hidden2">
        <div
          class="pro_li lf"
          v-for="(item, index) in item2"
          :class="{ keyboardSwitch: index == 30 }"
          :key="index"
          @click="other(item, index)"
        >
          {{ item }}
        </div>
        <div class="pro_li lf" @click="hide">⇩</div>
        <!-- 省 -->
        <div class="provinces" v-show="!hidden1">
          <div
            class="pro_li lf"
            v-for="(item, index) in item1"
            :class="{ keyboardSwitch: index == 30 }"
            :key="index"
            @click="sheng(item, index)"
          >
            {{ item }}
          </div>
          <div class="pro_li lf" @click="hide">⇩</div>
        </div>
        <!-- 号码	 -->
        <div class="keyNums" v-show="!hidden2">
          <div
            class="pro_li lf"
            v-for="(item, index) in item2"
            :class="{ keyboardSwitch: index == 30 }"
            :key="index"
            @click="other(item, index)"
          >
            {{ item }}
          </div>
          <div class="pro_li lf" @click="hide">⇩</div>
          <div class="bot">
            <div class="pro_d lf" @click="del" v-if="len != 1 && del_flag">
              <img src="@/assets/images/deletebtn.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="cenBtn">
        <!-- <div class='query_btn' :class="disableCss" @click="addTip">自主入场</div> -->
        <button
          class="query_btn"
          :class="{ query_btn_dis: !btnDis }"
          type="default"
          :disabled="btnDis"
          @click="addTip"
        >
          查 询
        </button>
      </div>
      <!-- <div class='modal' v-show='!modelHidden'>
        <div class='confirm-box'>
          <div class='confirm-box-top'>
            <div class='cens'>
              您要添加的车辆是
            </div>
            <div v-if="color==0" class='cens blueC'>蓝牌: {{carNoPlat}}</div>
            <div v-if="color==2" class='cens blueC'>新能源: {{carNoPlat}}</div>
            <div v-if="color==1" class='cens blueC'>黄牌: {{carNoPlat}}</div>
          </div>
          <div class='confirm-box-bottom'>
            <div class='custom-btn' @click='hideComfirmModal'>取消</div>
            <div class='custom-btn' @click='commitForm'>确认</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getToken } from "@/api";
import { getSign } from "@/assets/js/getSign";
import { Toast } from "vant";
export default {
  data() {
    return {
      btnDis: true,
      selectFlag: true, //true: 选择， false: 添加
      selectArr: [],
      selectVal: "",
      item1: [
        "京",
        "津",
        "沪",
        "渝",
        "蒙",
        "新",
        "藏",
        "宁",
        "桂",
        "贵",
        "云",
        "黑",
        "吉",
        "辽",
        "晋",
        "冀",
        "青",
        "鲁",
        "豫",
        "苏",
        "皖",
        "浙",
        "闽",
        "赣",
        "湘",
        "鄂",
        "粤",
        "琼",
        "甘",
        "陕",
        "字符",
        "川",
        "港",
        "澳",
        "领",
        "学",
        "使",
        "残",
      ],
      item2: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        "Q",
        "W",
        "E",
        "R",
        "T",
        "Y",
        "U",
        "I",
        "O",
        "P",
        "A",
        "S",
        "D",
        "F",
        "G",
        "H",
        "J",
        "K",
        "L",
        "Z",
        "汉字",
        "X",
        "C",
        "V",
        "B",
        "N",
        "M",
      ],
      colors: [
        {
          color: "蓝牌",
          style: "blue",
        },
        {
          color: "黄牌",
          style: "yellow",
        },
        {
          color: "黑牌",
          style: "block",
        },
        {
          color: "新能源",
          style: "green",
        },
      ],
      colorIndex: 1,
      hidden1: true,
      hidden2: true,
      carNo: ["青"],
      carNoPlat: "",
      len: 1,
      userCity: "",
      disableCss: "disableCss",
      needMoney: 0,
      color: 0,
      information: "",
      icon: "success",
      // modelHidden: true,
      flag: true,
      num: "1",
      activeCity: "",
      parkId: "",
      berthId: "",
      berthCode: "",
      plateNumber: "",
      del_flag: false,
    };
  },
  watch: {
    /* selectFlag(val) {
      console.log('selectFlag-->', val, this.carNo);
      if (val) {
        if (this.selectVal.length > 0) {
          this.btnDis = false;
        } else {
          this.btnDis = true;
        }
        this.carNo = [this.carNo[0]];
      } else {
        if ((this.color == 3 && this.carNo.length == 8) || this.carNo.length == 7) {
          this.btnDis = false;
        } else {
          this.btnDis = true;
        }
        this.selectVal = '';
      }
      this.hidden2 = val;
    }, */
    /* selectVal(val) {
      console.log('selectVal-->', val, this.selectArr);
      if (val.length > 0 && this.selectFlag) {
        this.btnDis = false;
      }
    }, */
    len() {
      console.log(
        "watch_length-->",
        this.carNo.length,
        this.num,
        "color-->",
        this.color
      );
      if (this.carNo.length > 2) {
        this.del_flag = true;
      }
      if (
        (parseInt(this.num) < 4 && this.carNo.length == 7) ||
        (this.num == 4 && this.carNo.length == 8)
      ) {
        this.plateNumber = this.carNo.join("");
        // let re = /^[\u4e00-\u9fa5]{1}[A-Z]{1}[A-Z_0-9]{5}$/;
        let re = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳领残使]{1}$/;
        if (this.num == 4) {
          re = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{5}[A-Z0-9挂学警港澳残]{1}$/;
        }
        console.log(this.plateNumber, re.test(this.plateNumber));
        if (re.test(this.plateNumber)) {
          this.btnDis = false;
        } else {
          this.btnDis = true;
        }
      } else {
        this.btnDis = true;
      }
    },
  },
  methods: {
    getPlateNumber() {
      // this.selectArr = ['123', '1234']
      /* queryMemberCars().then(
        res => {
          console.log('11->', res);
          res.value.forEach(item => {
            this.selectArr.push(item.plateNumber);
          });
        },
        err => {
          console.log(err);
        }
      ); */
    },
    hide() {
      this.hidden1 = true;
      this.hidden2 = true;
    },
    selectRadio(flag) {
      flag === "select" ? (this.selectFlag = true) : (this.selectFlag = false);
    },
    toggleClass(e) {
      console.log(e);
      let that = this;
      let data = e + 1;
      // // console.log(e.currentTarget.dataset.number)
      that.num = data;
      that.len = 1;
      this.carNo = ["青"];
      that.colorIndex = data;
      // that.setData({
      //   num: e.currentTarget.dataset.number,
      //   len: 1,
      //   carNo: ['京'],
      //   colorIndex: e.currentTarget.dataset.number,
      // })
      let colornum;
      if (data == 1 || data == 2 || data == 3) {
        //BLUE(0, "蓝"),
        // YELLOW(1, "黄"),
        // GREEN(2, "绿"),
        // YELLOW_GREEN(3, "黄绿"),
        // BLACK(4, "黑"),
        // WHITE(5, "白");
        colornum = data != 3 ? data - 1 : data + 1; //黑色下标是3  对应枚举是4
        that.color = colornum;
        that.disableCss = "disableCss";
        if (that.carNo.length == 7) {
          that.disableCss = "";
        } else if (that.carNo.length > 7) {
          that.carNo.length = that.carNo.length - 1;
          that.disableCss = "";
          // that.carNo = that.carNo;
        }
      } else {
        // 新能源 绿色 枚举 2 下标 4
        // colornum = data - 2;
        // that.color = colornum;
        that.color = data;
        that.disableCss = "disableCss";
        if (that.carNo.length == 8) {
          that.disableCss = "";
        }
      }
    },
    routerIndex() {
      this.$Router.switchTab({
        name: "home",
      });
    },
    addTip() {
      console.log(this.color, this.num);
      /* BLUE(0, "蓝"),
      YELLOW(1, "黄"),
      GREEN(2, "绿"),
      YELLOW_GREEN(3, "黄绿"),
      BLACK(4, "黑"),
      WHITE(5, "白"); */
      // let carMsg;
      let carNum;
      switch (parseInt(this.num)) {
        case 1:
          // carMsg = '蓝牌' + this.plateNumber;
          carNum = 0;
          break;
        case 2:
          // carMsg = '黄牌' + this.plateNumber;
          carNum = 1;
          break;
        case 4:
          // carMsg = '新能源牌' + this.plateNumber;
          carNum = 2;
          break;
        case 3:
          // carMsg = '黑牌' + this.plateNumber;
          carNum = 4;
          break;
      }
      this.$router.push({
        path: "/payList",
        query: { carNum, plateNumber: this.plateNumber },
      });
    },
    enterList() {
      console.log(this.parkId, this.plateNumber, this.berthId);
      /* getEntry({
        parkId: this.parkId,
        berthId: this.berthId,
        plateNumber: this.plateNumber,
        plateColor: this.color,
        carType: 0
      }).then(
        res => {
          // console.log(res)
		  if(res.state == 0){
			  this.$Router.push({
			    name: 'autoParkRecord',
			    params: {
			      backUrl: 'selectCar',
			      berthId: this.berthId
			    }
			  });
		  } else {
			  uni.showModal({
				  title: '提示',
				  // content: "尊敬的用户，自主停车在场记录不能大于5条，请您先确认出场，如有疑问请电话联系96900。",
				  content: res.desc,
				  showCancel: false
			  })
		  }
        },
        err => {
          console.log(err);
        }
      ); */
    },
    //回删车牌
    del() {
      var that = this;
      var ss = that.carNo;
      console.log(ss);
      if (ss.slice(0, -1).length == 0) {
        that.hidden1 = false;
        that.hidden2 = true;
        that.len = ss.slice(0, -1).length;
        that.carNo = ss.slice(0, -1);
      } else if (ss.slice(0, -1).length > 0) {
        console.log(ss.slice(0, -1).length);
        if (ss.slice(0, -1).length >= 8) {
          that.hidden1 = true;
          that.hidden2 = false;
          that.carNo[that.len] = "";
          that.len -= 1;
          // that.len = ss.slice(0, -1).length;
          // that.carNo = ss.slice(0, -1);
          that.disableCss = "";
        } else {
          that.hidden1 = true;
          that.hidden2 = false;
          that.carNo[that.len] = "";
          that.len -= 1;
          // that.len = ss.slice(0, -1).length;
          that.disableCss = "disableCss";
        }
      }
      if (ss[1] == "") {
        that.hidden1 = false;
        that.hidden2 = true;
      }
    },
    //获取车牌号码
    other(item, index) {
      var that = this;
      console.log(item);
      if (index == 30) {
        that.hidden1 = false;
        that.hidden2 = true;
        return false;
      }
      if (item == "I" || item == "O") {
        return;
      }
      var i = that.len;
      let carNo;
      console.log("carNo-->", that.carNo, i);
      for (var j = 0; j < i; j++) {
        if (!that.carNo[j]) {
          carNo = that.carNo;
          console.log("for_carNo-->", carNo);
          that.hidden1 = true;
          that.hidden2 = false;
          that.carNo = carNo;
          that.len = j;
          that.disableCss = "disableCss";
          // that.setData({
          //   hidden1: true,
          //   hidden2: false,
          //   carNo: carNo,
          //   len: j,
          //   disableCss: 'disableCss'
          // })
          return;
        }
      }
      that.carNo[i] = item + "";
      console.log("0-->", that.carNo, that.num);
      // let carNo = that.carNo;
      if (
        (that.carNo.length == 7 &&
          (that.num == 1 || that.num == 2 || that.num == 3)) ||
        (that.carNo.length == 8 && that.num == 4)
      ) {
        var re;
        if (that.carNo.length == 7) {
          // re = /^[\u4e00-\u9fa5]{1}[A-Z]{1}[A-Z_0-9]{5}$/;
          re = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳领残使]{1}$/;
          if (that.num == 4) {
            re = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{5}[A-Z0-9挂学警港澳残]{1}$/;
          }
          console.log(that.carNo);
          if (that.carNo.indexOf("") != -1) {
            that.len += 1;
          } else {
            console.log("1122-->", re.test(that.carNo.join("")));
            if (re.test(that.carNo.join(""))) {
              console.log(that.carNo + "--------- that carno");
              if (that.len == 6) {
                that.hidden1 = true;
                that.hidden2 = true;
                // that.len = 1
              }
              console.log("777-->", carNo);
              // that.carNo = carNo;
              that.len += 1;
              // that.len = that.carNo.length;
              that.disableCss = "";
            } else {
              console.log(that.carNo + "--------- that carno else");
              that.hidden1 = true;
              that.hidden2 = true;
              // that.carNo = carNo;
              that.len += 1;
              // that.len = that.carNo.length;
              that.disableCss = "disableCss";
              Toast("车牌号错误");
            }
          }
        } else if (that.carNo.length == 8) {
          re = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{5}[A-Z0-9挂学警港澳残]{1}$/;
          if (that.carNo.indexOf("") != -1) {
            that.len += 1;
          } else {
            if (re.test(that.carNo.join(""))) {
              if (that.len == 7) {
                that.hidden1 = true;
                that.hidden2 = true;
              }
              // that.carNo = carNo;
              that.len += 1;
              // that.len = i;
              that.disableCss = "";
            } else {
              that.hidden1 = true;
              that.hidden2 = true;
              // that.carNo = carNo;
              // that.len += 1;
              that.len = i;
              that.disableCss = "disableCss";
              Toast("车牌号错误");
            }
          }
        }
      } else {
        // that.carNo = carNo;
        that.len += 1;
        console.log("1-->", that.carNo);
        // that.len = that.carNo.length;
        that.disableCss = "disableCss";
      }
      that.plateNumber = that.carNo.join("");
      console.log(that.len);
    },
    //获取车牌省份
    sheng(item, index) {
      var that = this;
      if (index == 30) {
        that.hidden1 = true;
        that.hidden2 = false;
        return false;
      }
      console.log(item);
      var i = that.len;
      that.carNo[i] = item;
      var carNo = that.carNo;
      that.carNo = carNo;
      console.log(that.carNo);
      if (
        (that.len == 6 && (that.num == 1 || that.num == 2 || that.num == 3)) ||
        (that.len == 7 && that.num == 4)
      ) {
        that.hidden1 = true;
        that.hidden2 = true;
        that.len = that.carNo.length;
        let reg = "";
        if (that.num === 3) {
          reg = /^[A-Z0-9京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使港澳领]{1}[A-Z0-9京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼挂学警使港澳领]{6}$/;
        }
        if (that.carNo.length == 8) {
          reg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{5}[A-Z0-9挂学警港澳残]{1}$/;
        } else if (that.carNo.length == 7) {
          reg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳领残使]{1}$/;
        }
        that.plateNumber = that.carNo.join("");
        if (reg.test(that.plateNumber)) {
          that.disableCss = "";
        } else {
          that.disableCss = "disableCss";
          Toast("车牌号错误");
        }
        return false;
      }
      that.len += 1;
      if (that.carNo.length > 0) {
        that.hidden1 = true;
        that.hidden2 = false;
      }
    },
    //车牌输入获取焦点
    d1() {
      console.log("获取焦点d1");
      let that = this;
      that.hidden1 = false;
      that.hidden2 = true;
      that.len = 0;
    },
    //车牌输入失去焦点
    d2(e) {
      console.log("焦点d2", e, this.carNo, this.len);
      if (this.carNo.length < 2) {
        this.del_flag = false;
      } else {
        this.del_flag = true;
      }
      var that = this;
      var index = e;
      that.hidden1 = true;
      that.hidden2 = false;
      that.len = index;
    },
  },
  mounted() {
    // console.log(this.$Route.query);
    /* this.parkId = this.$Route.query.parkId;
    this.berthId = this.$Route.query.berthId;
    this.berthCode = this.$Route.query.berthCode; */
    this.getPlateNumber();
    this.hidden2 = false;
  },
  created() {
    //接口入参
    let data = getSign({
      timestamp: new Date().valueOf(),
    });
    getToken(data).then(
      (res) => {
        if (res.state == "10000") {
          sessionStorage.setItem("token", res.value.token);
        } else {
          Toast(res.desc);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
};
</script>

<style lang="less" scoped>
.addCarWrapper-combox {
  margin: 30px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}
.lcontainer {
  color: #000;
  padding: 0 30px;
  align-items: center;
}

.carName {
  margin: 70px 0 50px 0;
  display: flex;
  font-size: 64px;
  font-weight: 500;
  color: #000;
  text-align: left;
  align-items: flex-start;
}

.lf {
  float: left;
}

.rt {
  float: right;
}

.telMar {
  text-align: center;
  width: 100%;
  margin-top: 43px;
  /* margin-left: 8px; */
}

.tel {
  height: 100px;
  line-height: 100px;
  text-align: center;
  vertical-align: top;
  flex: 1;
  font-size: 48px;
}

.tel + .curr {
  border-bottom: 4px solid #000000;
}

.dropdown {
  width: 24px;
  height: 24px;
}

.carTip {
  font-size: 30px;
  color: #808080;
}

.carflex {
  width: 80%;
  display: flex;
  margin: 50px auto;
  justify-content: space-around;
}

.carflex view {
  flex: 0 0 auto;
  height: 58px;
  line-height: 58px;
  text-align: center;
  padding: 0px 20px;
  border-radius: 8px;
  font-size: 30px;
}

.carColorType {
  width: 504px;
  height: 88px;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 44px;
  background: rgba(247, 247, 247, 1);
  display: flex;
}

.carColor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 168px;
  height: 86px;
  border-radius: 44px;
  font-size: 29px;
  color: #333;
}

.blue {
  background: #009afa;
  color: #fff;
}

.green {
  background: #1fad3f;
  color: #fff;
}

.yellow {
  background: #feb80b;
  color: #fff;
}

.block {
  color: #fff;
  background: #333;
  border: 2px solid #333;
}

.chepai {
  width: 100%;
  margin-top: 80px;
}

.cen {
  width: 686px;
  height: 110px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  border: 1px solid rgba(217, 217, 217, 1);
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
}

.provinces {
  overflow: hidden;
  background-color: #ced3d9;
  padding: 30px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pro_li {
  font-size: 28px;
  height: 68px;
  width: 54px;
  line-height: 68px;
  text-align: center;
  border: 2px solid #ddd;
  margin: 10px 4px;
  background-color: #fff;
  border-radius: 5px;
}

.provinces:after {
  content: "";
  flex: auto;
}

.pro_close {
  width: 100px;
  height: 70px;
  line-height: 70px;
  font-size: 32px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #ddd;
  margin: 5px;
  margin-top: 16px;
  border-radius: 5px;
}

.pro_del {
  width: 100px;
  height: 70px;
  line-height: 70px;
  font-size: 32px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #ddd;
  margin: 5px;
  margin-top: 16px;
  border-radius: 5px;
}

.keyNums {
  overflow: hidden;
  background-color: #ced3d9;
  padding: 30px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10000;
  height: 432px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.keyNums:after {
  content: "";
  flex: auto;
}

.pro_ok {
  width: 100px;
  height: 70px;
  line-height: 70px;
  font-size: 32px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #ddd;
  margin: 5px;
  border-radius: 5px;
  margin-top: 16px;
}

.pro_d {
  width: 84px;
  height: 68px;
  line-height: 68px;
  font-size: 32px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #ddd;
  margin: 5px;
  margin-top: 10px;
  border-radius: 5px;
}

.pro_d > img {
  width: 47px;
}

.disableCss {
  pointer-events: none;
  color: #fff;
  background: #ccc;
  border: 1px solid #ccc;
  box-shadow: none;
  line-height: 98px;
  background: rgba(230, 230, 230, 1);
  border: none;
}

.cenBtn {
  text-align: center;
}

.needMoney {
  font-size: 30px;
  text-align: center;
}

.friendTip {
  font-size: 24px;
  color: #a6a6a6;
  margin: 34px 0 0 0;
}

.friendTip .tipY {
  color: #ff3859;
  display: inline-block;
}

.ignore {
  font-size: 32px;
  color: #4a90e2;
  text-align: right;
  width: 100%;
  bottom: 80px;
  margin-top: 20px;
}

.cens {
  /* margin-top: 18px; */
  line-height: 52px;
  color: #555;
  font-size: 26px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-around;
}

.blueC {
  color: #333;
  font-weight: 600;
  font-size: 34px;
  margin-top: 20px;
}

.confirm-box-top {
  font-size: 34px;
  color: #404040;
}

.keyNums .pro_li:nth-child(18) {
  background-color: #ced3d9;
}

.keyNums .pro_li:nth-child(19) {
  background-color: #ced3d9;
}

.province {
  width: 130px;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 35px;
  box-sizing: border-box;
  border-right: solid 1px #ccc;
  margin-right: 20px;
}

.query_btn {
  width: 380px;
  height: 96px;
  margin: 0 auto;
  margin-top: 110px;
  color: #ccc;
  text-align: center;
  line-height: 40px;
  // background: rgba(52, 65, 74, 1);
  border-radius: 10px;
  font-size: 40px;
  border: 0;
  &.query_btn_dis {
    background-color: #2f74ed;
    color: #fff;
  }
}
.keyboardSwitch {
  width: 90px;
  height: 68px;
  line-height: 68px;
  margin: 10px 4px;
  text-align: center;
}
</style>
