// 统一管理api
import { post, get } from './http'
//获取token
export const getToken = (params) => get('/api/token', params)
//欠费列表
export const getCarDebtList = (params) => post('/api/order/debt/query', params)

export const getById = (params) => get('/wechat/2.0/parkrecord/getById', params)

export const getGEnterExitPhotos = (params) => get('/wechat/2.0/parkrecord/getGEnterExitPhotos', params)
//欠费订单下单
export const postDeptOrders = (params) => post('/api/order/create', params)
//调起摄像头-扫码-获取authCode
//下单付款时使用
export const postPay = (params) => post('/api/pay/payment/unifiedOrder', params)
