import Vue from 'vue'
import VueRouter from 'vue-router'
// import layout_top from '@/layout/layout_top.vue'
import Home from '@/views/Home.vue'
import arrear from '@/views/arrear.vue'
import details from '@/views/details.vue'
import paytype from '@/views/paytype.vue'
import paySuccess from '@/views/paySuccess.vue'
import payFail from '@/views/payFail.vue'
import paylist from '@/views/payList'
import payDetails from '@/views/payDetails'
Vue.use(VueRouter)
const routes = [
  /* {
    path: '/',
    redirect: '/home',
    component: layout_top,
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: Home
      }
    ]
  }, */
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/paylist',
    name: 'paylist',
    component: paylist
  },
  {
    path: '/payDetails',
    name: 'payDetails',
    component: payDetails
  },
  {
    path: '/arrear',
    name: 'arrear',
    component: arrear
  },
  {
    path: '/details',
    name: 'details',
    component: details
  },
  {
    path: '/paytype',
    name: 'paytype',
    component: paytype
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: paySuccess
  },
  {
    path: '/payFail',
    name: 'payFail',
    component: payFail
  },
  {
    path: '*',
    redirect: '/home'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
