<template>
  <div class="paySuccess">
    <van-nav-bar
      title="支付成功"
      left-arrow
      @click-left="onClickLeft"/>
      <div class="paySuccess_top">
        <h5><van-icon name="close" />支付失败</h5>
      </div>
      <van-button class="paySuccess_btn" type="danger">重新支付</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: ''
    }
  },
  methods: {
    onClickLeft() {
      console.log('返回');
    }
  }
}
</script>
<style lang="less" scoped>
.paySuccess{
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;

}
.paySuccess_top{
  background-color: rgb(218,53,60);
  >h5{
    text-align: center;
    font-size:60px;
    margin: 0;
    padding: 140px 0;
    color: #fff;
    >i{
      margin-right: 20px;
      font-size: 80px;
      vertical-align: text-top;
    }
  }
}
.paySuccess_btn{
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 40px;
}
</style>