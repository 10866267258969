<template>
  <div class="scan">
    <!-- <div class="sectionview">
      <div id="qr-reader"
           style="width:100%;height: 100%;"></div>
    </div> -->
    <van-nav-bar v-if="false"
                 title="支付方式"
                 class="title-nav-bar"
                 left-arrow
                 @click-left="onClickLeft" />
    <div class="paytype-content">
      <p>还需支付：</p>
      <p class="paytype-content-p"> <span>{{$route.query.shouldPay ? ($route.query.shouldPay / 100).toFixed(2) : 0 }}</span> 元</p>
    </div>
    <ul class="paytype_pay">
      <li @click="payType('wechat')">
        <van-icon name="wechat"
                  color="rgb(53,173,70)" />
        <span>微信</span>
      </li>
      <li @click="payType('alipay')">
        <van-icon name="alipay"
                  color="rgb(0,173,234)" />
        <span>支付宝</span>
      </li>
    </ul>
    <van-popup v-model="show"
               class="payType_popup">
      <p>收款码</p>
      <img class="payType_popup_img"
           :src="require('@/assets/images/money.jpg')"
           alt="">
    </van-popup>
  </div>
</template>

<script>
/* eslint-disable */
import { postPay } from '@/api'
import { getSign } from "@/assets/js/getSign";
import { Toast } from 'vant';
export default {
  data () {
    return {
      value: '',
      show: false,
      cameraId: ''
    }
  },
  methods: {
    stop () {
      this.html5QrCode
        .stop()
        .then(ignore => {
          console.log('QR Code scanning stopped.');
        })
        .catch(err => {
          Toast(err)
        });
    },
    start (num) {
      this.html5QrCode = new Html5Qrcode('qr-reader');
      this.html5QrCode
        .start(
          this.cameraId, // retreived in the previous step.
          {
            fps: 10, // sets the framerate to 10 frame per second
            qrbox: 250 // sets only 250 X 250 region of viewfinder to
            // scannable, rest shaded.
          },
          qrCodeMessage => {
            // do something when code is read. For example:
            // alert('qrCodeMess:' + qrCodeMessage)
            if (qrCodeMessage) {
              // alert(qrCodeMessage);
              // this.getCode(qrCodeMessage);
              //接口入参
              let signData = getSign({
                token: sessionStorage.getItem('token'),
                timestamp: new Date().valueOf(),
                paymentId: this.$route.query.paymentId,
                callbackUrl: this.$route.query.callbackUrl,
                payType: num,//1:支付宝，2：微信
                money: this.$route.query.shouldPay,
                orderType: 1,
                authCode: qrCodeMessage//扫码得到
              });
              postPay(signData).then(res => {
                console.log(res);
                if (res.state == '0') {
                  this.$router.push({ path: '/paySuccess', query: { type: res.value.serviceType, money: res.value.money, time: res.value.payTime } })
                } else {
                  Toast(res.desc);
                }
              }, err => {
                console.log(err);
              })
              this.stop();
            }
          },
          errorMessage => {
            // Toast(errorMessage)
          }
        )
        .catch(err => {
          Toast(err);
        });
    },
    getCameras (num) {
      alert(Html5Qrcode);
      Toast(Html5Qrcode);
      Html5Qrcode.getCameras()
        .then(devices => {
          /**
           * devices would be an array of objects of type:
           * { id: "id", label: "label" }
           */
          if (devices && devices.length) {
            if (devices.length > 1) {
              this.cameraId = devices[1].id;
            } else {
              this.cameraId = devices[0].id;
            }
            // alert('cameraId' + this.cameraId);
            this.start(num);
          }
        })
        .catch(err => {
          alert(err);
        });
    },
    onClickLeft () {
      console.log('返回');
      this.$router.go(-1)
    },
    payType (type) {
      if (type === 'wechat') {
        // 微信
        // this.show = true;
        this.getCameras(2);
      } else {
        // 支付宝
        // this.show = true;
        this.getCameras(1);
      }
    }
  }
}
/* eslint-enable */
</script>
<style lang="less" scoped>
.scan {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.paytype-content {
  padding: 20px 30px;
  span {
    color: rgb(252, 168, 59);
  }
  > p {
    font-size: 40px;
  }
  .paytype-content-p {
    text-align: center;
    > span {
      color: rgb(252, 168, 59);
      font-size: 80px;
    }
  }
}
.paytype_pay {
  margin-top: 120px;
  > li {
    text-align: center;
    border-top: 1px solid rgb(233, 230, 230);
    font-size: 60px;
    padding: 30px 0;
    &:last-child {
      border-bottom: 1px solid rgb(233, 230, 230);
    }
    > span {
      margin-left: 30px;
    }
    > i {
      vertical-align: bottom;
      font-size: 100px;
    }
  }
}
.payType_popup {
  padding: 30px;
  .payType_popup_img {
    width: 400px;
    height: 400px;
  }
  > p {
    text-align: center;
    font-size: 30px;
  }
}
</style>