var SHA1 = require("js-sha1");
export function getSign(data) {
  //拼接签名对象
  let obj = Object.assign(
    {
      app_id: "2020120178336899",
      version: "1.0",
    },
    data
  );
  //属性ASCII排序
  let sortData = {};
  Object.keys(obj)
    .sort()
    .map((key) => {
      sortData[key] = obj[key];
    });
  console.log(obj, "==========", sortData);
  //签名字符串拼接
  let str = '';
  Object.keys(sortData).map((key) => {
    str = str ? str + `&${key}=${sortData[key]}` : `${key}=${sortData[key]}`
  });
  //追加secret
  let newStr = str + "184bc63b4be64c61aa28019b6ed0dddd";
  console.log(newStr)
  //SHA1
  let sign = SHA1(newStr).toUpperCase();
  data.sign = sign;
  return data;
}