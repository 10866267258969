import axios from 'axios'
import { Dialog } from 'vant';
// import qs from 'qs';
// import router from '@/router';
const http = axios.create({
  timeout: 10000, // 设置超时时间10s
  // baseURL: process.env.NODE_ENV === 'production' ? '/lulu' : '/api'
  baseURL: '' //'/api'
})
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
http.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// 对返回错误码二次处理
let httpCode = {
  400: '请求参数错误',
  401: '权限不足, 请重新登录',
  403: '服务器拒绝本次访问',
  404: '请求资源未找到',
  500: '内部服务器错误',
  501: '服务器不支持该请求中使用的方法',
  502: '网关错误',
  504: '网关超时'
}

/** 添加请求拦截器 **/
http.interceptors.request.use(config => {
  // config.headers['authToken'] = sessionStorage.getItem('token') || ''
  config.headers['Inner-Access-Authorization'] = `{"app_id":"2020120178336899","version":1.0 }`
  // loading start
  if (config.method === 'get') {
    config.params = {
      ...config.params,
    }
  }
  // 导出文件
  if (config.url.includes('pur/contract/export')) {
    config.headers['responseType'] = 'blob'
  }
  // 文件上传
  if (config.url.includes('pur/contract/upload')) {
    config.headers['Content-Type'] = 'multipart/form-data'
  }
  return config
}, error => {
  // 请求错误
  return Promise.reject(error)
})

/** 添加响应拦截器  **/
http.interceptors.response.use(response => {
  console.log('response-->', response);
  /* if (response.data.desc === 'success') {
    return Promise.resolve(response.data)
  } else if (response.data.state == 1) {
    // if (response.data.code === 500) {
    //   Dialog({ message: '登录超时，请重新的登录' });
    //   router.push({path: `/login`})
    // }
    Toast(response.data.desc)
    return Promise.reject(response.data)
  } */
  return Promise.resolve(response.data)
}, error => {
  if (error.response) {
    let hint = error.response.status in httpCode ? httpCode[error.response.status] : error.response.data.message
    Dialog({ message: hint });
    // if (error.response.status === 401) {
    //   router.push({
    //     path: `/login`
    //   })
    // }
    return Promise.reject(error)
  } else {
    Dialog({ message: '请求超时, 请刷新重试' });
    return Promise.reject(new Error('请求超时, 请刷新重试'))
  }
})

/* 统一封装get请求 */
export const get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    http({
      method: 'get',
      url,
      params
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

/* 统一封装post请求  */
export const post = (url, data={}) => {
  return new Promise((resolve, reject) => {
    http({
      method: 'post',
      url,
      data: data//qs.stringify(data)
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}
