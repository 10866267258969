<template>
  <div class="arrear">
    <van-nav-bar v-if="false"
                 title="历史欠费列表"
                 class="title-nav-bar"
                 left-arrow
                 @click-left="onClickLeft" />
    <van-row class="arrear_title">
      <van-col span="12">
        <p>{{$route.query.plateNumber}}</p>
      </van-col>
      <van-col span="12"
               style="text-align:right">
        <p>当前选中 ¥{{totalMoney ? (totalMoney / 100).toFixed(2) : 0 }}</p>
        <p>总欠费 ¥{{pageTotalMoney  ? (pageTotalMoney / 100).toFixed(2) : 0 }}</p>
      </van-col>
    </van-row>
    <ul class="arrear-content">
      <van-checkbox-group v-model="selectList"
                          ref="checkboxGroup">
        <van-pull-refresh v-model="isLoading"
                          @refresh="onRefresh">
          <van-list v-model="loading"
                    :finished="finished"
                    finished-text=""
                    @load="onLoad">
            <li v-for="(item, index) in list"
                :key="index">
              <van-row type="flex"
                       justify="center"
                       align="center">
                <van-col span="14"
                         offset="2">
                  <h5>{{item.name}}</h5>
                  <p>{{item.createdTime}}</p>
                </van-col>
                <van-col span="8">
                  <span @click="selectItem(index)">
                    <van-row>
                      <van-col span="6"
                               style="text-align:right">
                        <van-checkbox :name="index"
                                      shape="square"> </van-checkbox>
                      </van-col>
                      <van-col span="18"><span>{{item.money ? (item.money / 100).toFixed(2) : 0 }}元</span></van-col>
                    </van-row>
                  </span>
                </van-col>
              </van-row>
              <!-- <van-divider /> -->
            </li>
          </van-list>
          <p class="NoOrder"
             v-if="list.length == 0">未查询到缴费订单</p>
        </van-pull-refresh>
      </van-checkbox-group>
    </ul>
    <div class="arrear_btn_div">
      <van-checkbox v-model="allChecked"
                    @click="changeAllChecked"
                    shape="square">全选</van-checkbox>
    </div>
    <van-button class="arrear_btn"
                type="warning"
                @click="toPay">缴纳欠费</van-button>
  </div>
</template>

<script>
import { getCarDebtList, getById, postDeptOrders } from '@/api'
import { getSign } from "@/assets/js/getSign";
import { formatDate } from "@/assets/js/common";
import { Toast } from 'vant';
export default {
  data () {
    return {
      value: '',
      allChecked: false,
      selectList: [],
      list: [],
      pageSize: 10,
      pageNum: 0,
      loading: false,
      finished: false,
      isLoading: false,
      totalMoney: 0,//当前选中金额
      pageTotalMoney: 0,//每页欠费总额
      radio: '63',
    }
  },
  watch: {
    allChecked (falg) {
      if (falg) {
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        if (this.selectList.length === 0) {
          this.selectList = []
        }
      }
    },
    selectList: {
      handler (val) {
        this.totalMoney = 0
        val.forEach(item => {
          this.totalMoney += this.list[item].money;
        })
      },
      deep: true
    }
  },
  methods: {
    closeDialog () {
      console.log('close');
    },
    toPay () {
      if (this.selectList.length === 0) {
        Toast('请先选择订单，进行支付');
        return false;
      }
      let deptIds = "";
      this.selectList.forEach((item) => {
        console.log(item)
        deptIds += (this.list[item].debtOrderId + ',')
      })
      let debtOrderIds = deptIds.slice(0, deptIds.length - 1).split(",");
      // 接口入参
      let signData = getSign({
        token: sessionStorage.getItem('token'),
        debtOrderIds: JSON.stringify(debtOrderIds),//JSON.stringify(debtOrderIds),
        timestamp: new Date().valueOf(),
      });
      signData.debtOrderIds = debtOrderIds//debtOrderIds;
      postDeptOrders(signData).then(res => {
        // return res.value
        if (res.state == "10000") {
          this.$router.push({ path: '/paytype', query: { paymentId: res.value.paymentId, shouldPay: res.value.shouldPay, callbackUrl: res.value.callbackUrl } })//跳转支付类型选择页面
        } else {
          Toast(res.desc);
        }
      }, err => {
        console.log(err);
      })
    },
    toPayDetails (parkRecordId) {
      getById({ parkRecordId }).then(res => {
        if (res.state == 1) {
          Toast(res.desc);
          return;
        }
        this.$router.push({ path: '/payDetails', query: { parkRecordId } })
      }, err => {
        console.log(err);
      })
    },
    onClickLeft () {
      console.log('返回');
      this.$router.go(-1)
    },
    onRefresh () {
      console.log('加载......');
      this.finished = false;
      this.loading = true;
      this.pageNum = 1
      this.list = []
      this.getDataList()
    },
    onLoad () {
      this.loading = true
      this.pageNum++;
      this.getDataList()
    },
    changeAllChecked () {
      if (!this.allChecked) {
        this.selectList = []
      }
    },
    selectItem (index) {
      if (this.selectList.includes(index)) {
        let i = this.selectList.indexOf(index)
        this.selectList.splice(i, 1)
      } else {
        this.selectList.push(index)
      }
      if (this.selectList.length === this.list.length) {
        this.allChecked = true
      } else {
        this.allChecked = false
      }
    },
    getDataList () {
      //接口入参
      let data = getSign({
        token: sessionStorage.getItem('token'),
        plates: JSON.stringify([{ plateColor: parseInt(this.$route.query.carNum), plateNumber: this.$route.query.plateNumber }]),
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        timestamp: new Date().valueOf(),
      });
      data.plates = [{ plateNumber: this.$route.query.plateNumber, plateColor: parseInt(this.$route.query.carNum) }];
      getCarDebtList(data).then(res => {
        this.loading = false
        this.isLoading = false
        if (res.state == "10000") {
          res.value.list.length > 0 && res.value.list.forEach(item => {
            this.list.push({
              name: item.parkName,
              createdTime: formatDate(item.createdTime),
              intime: item.entryTime,
              outtime: item.exitTime,
              money: item.money,
              parkRecordId: item.parkRecordId,
              debtOrderId: item.debtOrderId
            })
          });
          //计算每页总欠费金额
          this.pageTotalMoney = 0
          this.list.forEach(item => {
            this.pageTotalMoney += item.money;
          })
          if (res.value.list.length < this.pageSize) {
            this.finished = true
          }
        } else {
          Toast(res.desc);
        }
      }, err => {
        console.log(err);
      })
    }
    ,
    // // 63 微信 ， 64 支付宝
    isWxOrAli () {
      var ua = window.navigator.userAgent.toLowerCase();
      //判断是不是微信
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // alert('在微信里');
        console.log("当前环境", "在微信里")
        this.radio = 63
        return;
      }
      //判断是不是支付宝
      if (ua.match(/AlipayClient/i) == "alipayclient") {
        // alert('在支付宝里');
        console.log("当前环境", "在支付宝")
        this.radio = 64
        return;
      }
    }
  },
  mounted () {
    this.isWxOrAli();
  }
}
</script>
<style lang="less" scoped>
.arrear {
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 50px;
  background-color: #f7f7f7;
}

.arrear_title {
  margin: 0;
  background-color: rgb(47, 164, 232);
  color: #fff;
  font-size: 36px;
  padding: 36px 40px;
  p {
    padding: 0;
    margin: 0;
    line-height: 66px;
  }
}

.arrear-content {
  /deep/ .van-divider {
    border-color: #d5d6da;
  }
  // padding: 30px;
  /deep/ .van-pull-refresh__track {
    padding: 3px;
  }
  /deep/ .van-pull-refresh {
    min-height: calc(80vh - 60px);
  }
  li {
    margin: 30px;
    padding: 20px 0;
    background-color: #fff;
    border-radius: 15px;
    h5 {
      font-size: 30px;
      margin: 0;
      line-height: 66px;
    }
    p {
      font-size: 24px;
    }
    span {
      font-size: 30px;
      color: red;
    }
  }
}
.arrear_btn_div {
  float: right;
  margin-right: 60px;
  font-size: 30px;
}
.arrear_btn {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #e6a23c;
  height: 120px;
  line-height: 120px;
  font-size: 30px;
}
.radio_div {
  padding: 20px 40px;
  box-sizing: border-box;
  /deep/ .van-radio {
    margin-bottom: 20px;
  }
}
.dialog_p {
  text-align: center;
  font-size: 36px;
  margin-bottom: 0px;
}
.NoOrder {
  font-size: 18px;
  text-align: center;
}
</style>