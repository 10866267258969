<template>
  <div class="pay_details">
    <van-nav-bar
      title="缴费详情"
      left-arrow
      @click-left="onClickLeft"/>
      <h3>{{detailsData.money ? (detailsData.money/ 100).toFixed(2): 0 }}<span>元</span></h3>
      <p>{{detailsData.parkName}}</p>
      <div class="pay_details_con">
        <span>订单详情</span>
        <ul>
          <li>
            <span>车牌号</span>
            <span>{{detailsData.plateNumber}}</span>
          </li>
          <li>
            <span>入场时间</span>
            <span>{{detailsData.entryTimeStr}}</span>
          </li>
          <li>
            <span>出场时间</span>
            <span>{{detailsData.exitTimeStr}}</span>
          </li>
          <li>
            <span>停车时长</span>
            <span>{{detailsData.parkTime}}</span>
          </li>
        </ul>
        <p>
          <span>订单总额</span>
          <span>¥ {{detailsData.totalMoney ?  (detailsData.totalMoney/ 100).toFixed(2): 0 }}</span>
        </p>
        <p>
          <span>应付总额</span>
          <span>¥ {{detailsData.money ? (detailsData.money/ 100).toFixed(2): 0 }}</span>
        </p>
      </div>
      <p>你的车辆已经离场，请及时支付车费</p>
      <p @click="checkImg">查看出入场照片></p>
      <div class="pay_details_con_btn">
        <van-button type="danger" size="large" @click="toPay">去支付</van-button>
      </div>
      <van-popup v-model="show" :style="{ width: '85%' }">
        <div class="img_div">
          <p>入场图片</p>
          <img :src="imgData.enterFeatureUrl" alt="">
          <p>出场图片</p>
          <img :src="imgData.exitFeatureUrl" alt="">
        </div>
      </van-popup>
      <van-dialog v-model="dialogShow" title="应付金额" show-cancel-button @cancel="closeDialog" @confirm="confirmDialog">
        <p class="dialog_p">¥ {{detailsData.money ? (detailsData.money/ 100).toFixed(2): 0 }}</p>
        <div class="radio_div">
          <p></p>
          <!-- <van-radio-group v-model="radio">
            <van-radio name="63">微信</van-radio>
            <van-radio name="64">支付宝</van-radio>
          </van-radio-group> -->
        </div>
      </van-dialog>
  </div>
</template>

<script>
import { getById, getGEnterExitPhotos, postDeptOrders, postPay } from '@/api'
export default {
  data() {
    return {
      value: '',
      detailsData: {},
      parkRecordId: 0,
      show: false,
      dialogShow: false,
      imgData: {},
      payParams: {},
      radio: '63',
      paytype: 63 // 63 微信 ， 64 支付宝
    }
  },
  methods: {
    onClickLeft() {
      console.log('返回');
      this.$router.go(-1)
    },
    closeDialog(){
      console.log('close');
    },
    confirmDialog(){
      // console.log('confirm', this.radio, this.payParams);
      postPay({paymentId: this.payParams.paymentId, money: this.payParams.money, orderType: 1, paytype: this.radio}).then(res => {
        console.log(res);
        let  url = res.value 
        if(url){
          window.location.href = url
        } else {
          alert(res.desc)
        }
        
      }, err => {
        console.log(err);
      })
    },
    async toPay(){
      let data = this.payParams = await postDeptOrders({deptIds: this.detailsData.debtOrderId + ''}).then(res => {
        //  this.payParams = res.value
        return res.value
      }, err => {
        console.log(err);
      })
      console.log('去支付-->', data);
      if (data) {
        this.dialogShow = true
      }
    },
    getData(id){
      getById({parkRecordId: id}).then(res => {
        this.detailsData = res.value
      }, err => {
        console.log(err);
      })
    },
    checkImg(){
      getGEnterExitPhotos({parkRecordId: this.parkRecordId}).then(res => {
        this.imgData = res.value
        this.show = true
      }, err => {
        console.log(err);
      })
    }
     ,
  // // 63 微信 ， 64 支付宝
    isWxOrAli() {
      var ua = window.navigator.userAgent.toLowerCase();
      //判断是不是微信
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
			// alert('在微信里');
      console.log("当前环境", "在微信里")
        this.radio =  63
        return ;
      }
      //判断是不是支付宝
      if (ua.match(/AlipayClient/i) == "alipayclient") {
			// alert('在支付宝里');
       console.log("当前环境", "在支付宝")
        this.radio =  64
        return;
      }
    }
  },
  mounted() {
    this.isWxOrAli();
    let id = this.parkRecordId = this.$route.query.parkRecordId
    this.getData(id)
  }
}
</script>
<style lang="less" scoped>
.pay_details{
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
  >h3{
    text-align: center;
    font-size: 72px;
    margin-top: 20px;
    margin-bottom: 0px;
    >span{
      font-size: 36px;
    }
  }
  >p{
    text-align: center;
    font-size: 24px;
    margin-top: 0px;
  }
  .pay_details_con{
    padding: 0 30px;
    >span{
      display: block;
      margin-top: 60px;
      text-align: center;
      font-size: 24px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 16px;
        width: 35%;
        height: 1px;
        background-color: rgb(224, 222, 222);
      }
      &::after{
        content: '';
        position: absolute;
        right: 0;
        top: 16px;
        width: 35%;
        height: 1px;
        background-color: rgb(224, 222, 222);
      }
    }
    >ul{
      li{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        >span{
          font-size: 24px;
        }
      }
    }
    >p{
      display: flex;
      justify-content: space-between;
      border-top: 1px solid rgb(224, 222, 222);
      margin-top: 40px;
      padding-top: 20px;
      >span{
        font-size: 24px;
      }
    }
  }
  .pay_details_con_btn{
    padding: 0 30px;
    /deep/ .van-button--large{
      border-radius: 10px;
    }
  }
  .img_div{
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    >img{
      width: 100%;
    }
    >p{
      text-align: center;
      font-size: 24px;
      margin-top: 0;
    }
  }
  .radio_div{
    padding: 20px 40px;
    box-sizing: border-box;
    /deep/ .van-radio{
      margin-bottom: 20px;
    }
  }
  .dialog_p{
    text-align: center;
    font-size: 36px;
    margin-bottom: 0px;
  }
}
</style>