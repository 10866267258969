<template>
  <div class="paySuccess">
    <van-nav-bar
    v-if="false"
      title="支付成功"
      class="title-nav-bar"
      @click-left="onClickLeft"/>
      <div class="paySuccess_top" :style="{backgroundColor: $route.query.type === '2' ? 'rgb(0,182,71)' : '#409eff'}">
        <h5><van-icon name="passed" />支付成功</h5>
      </div>
      <div class="paySuccess_text">
        <p>支付方式：<span>{{$route.query.type === '2' ? '微信支付' : '支付宝支付' }}</span></p>
        <p>支付时间：<span>{{$route.query.time }}</span></p>
        <p>支付金额：<span style="color: red">{{$route.query.money ? ($route.query.money / 100).toFixed(2) : 0 }}</span></p>
      </div>
      <van-button class="paySuccess_btn" :type="$route.query.type === '2' ? 'primary' : 'info'" @click="over">完成</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: ''
    }
  },
  methods: {
    onClickLeft() {
      console.log('返回');
    },
    over(){
      this.$router.push({path: '/'})
    }
  }
}
</script>
<style lang="less" scoped>
.paySuccess{
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;

}
.paySuccess_top{
  // background-color: rgb(0,182,71);
  >h5{
    text-align: center;
    font-size:60px;
    margin: 0;
    padding: 140px 0;
    color: #fff;
    >i{
      margin-right: 20px;
      font-size: 80px;
      vertical-align: text-top;
    }
  }
}
.paySuccess_text{
  padding: 30px;
  >p{
    font-size: 28px;
    margin: 0;
    margin-bottom: 30px;
  }
}
.paySuccess_btn{
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 40px;
}
</style>