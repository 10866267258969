<template>
  <div class="details">
    <van-nav-bar
      title="欠费追缴"
      left-arrow
      @click-left="onClickLeft"/>
      <img :src="require('@/assets/images/top.png')" alt="" class="details_img">
      <div class="details_content">
        <p>车 牌：<span>京BZ3638</span></p>
        <p>车牌颜色：<span>蓝</span></p>
        <p>驶入时间：<span>2018-08-13</span> <i>15:54</i></p>
        <p>驶出时间：<span>2018-08-13</span> <i>17:41</i></p>
        <p>停车时间：<span>2:13</span></p>
        <p>应付金额：<span class="details_content_money">44.27元</span></p>
      </div>
      <van-button class="details_btn" type="warning">缴纳欠费</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: ''
    }
  },
  methods: {
    onClickLeft() {
      console.log('返回');
    }
  }
}
</script>
<style lang="less" scoped>
.details{
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
}
.details_img{
  width: 100%;
}
.details_content{
  padding: 20px 50px;
  padding-top: 0;
  >p{
    font-size: 28px;
    >i{
      font-style: normal;
    }
    .details_content_money{
      font-size: 40px;
      color: red;
    }
  }
}
.details_btn{
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>